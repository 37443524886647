import { Unit } from "#imports"

export type TranslatedEnum<T extends string | symbol> = { [key in T]: string }

const unit = {
  [Unit.enum["kWh"]]: "kWh",
  [Unit.enum["MWh"]]: "MWh",
  [Unit.enum["GWh"]]: "GWh",
  [Unit.enum["TWh"]]: "TWh",
  [Unit.enum["W"]]: "W",
  [Unit.enum["kW"]]: "kW",
  [Unit.enum["MW"]]: "MW",
  [Unit.enum["GW"]]: "GW",
  [Unit.enum["TW"]]: "TW",
  [Unit.enum["Wh/m²"]]: "Wh/m²",
  [Unit.enum["m"]]: "m",
  [Unit.enum["%"]]: "",
  [Unit.enum["latlng"]]: "",
  [Unit.enum["eur/kW"]]: "€/kW",
  [Unit.enum["eur/kWh"]]: "€/kWh",
  [Unit.enum["eur/MWh"]]: "€/MWh",
  [Unit.enum["ceur/kWh"]]: "c€/kWh",
  [Unit.enum.ceur]: "c€",
  [Unit.enum.eur]: "€",
  [Unit.enum.keur]: "k€",
  [Unit.enum.Meur]: "M€",
  [Unit.enum.Geur]: "Md€",
  [Unit.enum.dimensionless]: "",
  [Unit.enum["eur/dimensionless"]]: "€",
  [Unit.enum["coef_l"]]: "",
} satisfies TranslatedEnum<Unit>

const universal_contract_types = {
  ao_fs11: "AO FS11",
  ao_fs13: "AO FS13",
  ao_fs15: "AO FS15",
  ao_fsf19: "AO FSF19",
  ao_fsi17: "AO FSI17",
  ao_fv11: "AO FV11",
  ao_fv13: "AO FV13",
  ao_fv14: "AO FV14",
  ao_fv16: "AO FV16",
  autoconso: "Autoconso",
  cr_e16: "CR E16",
  cr_e17: "CR E17",
  cr_fa16: "CR FA16",
  cr_fa17: "CR FA17",
  cr_fet17_p1: "CR FET17 P1",
  cr_fet17_p2: "CR FET17 P2",
  cr_fet17_p3: "CR FET17 P3",
  cr_fet17_p4: "CR FET17 P4",
  cr_fet17_p5: "CR FET17 P5",
  cr_fet17_p6: "CR FET17 P6",
  cr_fse17: "CR FSE17",
  cr_fsf19: "CR FSF19",
  cr_fsi17: "CR FSI17",
  cr_fv16_b: "CR FV16 B",
  cr_fv16_s: "CR FV16 S",
  cr_fv21_b: "CR FV21 B",
  da: "Day Ahead",
  m0: "M0",
  m0_imbalance: "M0 Imbalance",
  maintenance: "Maintenance",
  multi_tarif: "Multi Tariff",
  oa_e01: "OA E01",
  oa_e06: "OA E06",
  oa_e08: "OA E08",
  oa_e14: "OA E14",
  oa_h01: "OA H01",
  oa_h07: "OA H07",
  oa_h16: "OA H16",
  oa_s01: "OA S01",
  oa_s06: "OA S06",
  oa_s10: "OA S10",
  oa_s10_b: "OA S10 B",
  oa_s11: "OA S11",
  oa_s11_c: "OA S11 C",
  oa_s11_m: "OA S11 M",
  oa_s11_mv2: "OA S11 MV2",
  oa_s11_s: "OA S11 S",
  oa_s17: "OA S17",
  oa_s21: "OA S21",
  fix: "FIX",
  bg16: "BG16",
  gc: "GC",
  FH17: "FH17",
  lease: "Lease",
}

export const universal_translations = {
  unit,
  contract: { type: universal_contract_types },
  "fr-FR": "Français",
  "en-US": "English",
}
